import axios from 'axios'
import router from '../router'
import { store } from '../store'
const API = (function () {
    let token = null
    function API() {
        const baseURLServer = `https://api.desarrollo-web.mx/api`
        const CONST_TYPE = {
            'GET': 'GET',
            'POST': 'POST'
        };

        token = store.getters['auth/getToken']
        axios.defaults.baseURL = baseURLServer;
        axios.defaults.withCredentials = true;
        axios.defaults.timeout = (60 * 30 * 1000);
        axios.defaults.headers = { 'Content-Type': 'application/json', 'Authorization' : false}
        axios.defaults.headers['Authorization'] = (token !== null) ? token : false;

        // axios.defaults.maxContentLength = 500;
        this.get = async ( url, params = {}, type = CONST_TYPE.GET ) => {
            let resp = {
                error: 1,
                message: null,
                data: null
            };
            params.files = await getFiles(params)
            if (CONST_TYPE.GET === type || CONST_TYPE.POST === type) {
                store.dispatch('home/setMainLoader',true)
                await axios( {
                    method: type,
                    url: url,
                    data: params
                } ).then(response => {
                        if (response.status === 200) 
                            resp = response.data;
                    })
                    .catch(error => {
                        resp.message = error.message;
                    })
                    .finally(function () {
                        store.dispatch('home/setMainLoader',false)
                        if (resp.error === 1 && typeof resp.message.toJSON === 'function') {
                            let status = resp.message.toJSON();
                            if (typeof status.code === 'undefined' && (status.message === 'Network Error' || status.message.match(/code 502/) !== null)) {
                                resp.error = 2;
                                resp.message = 'La obtención de la información se continuara en el servidor, sele enviara notificacion cuando este completa';
                            }
                        } else if (resp.error === 1 && resp.cveError !== undefined ? resp.cveError === 'LOGOUT' : false){
                            store.dispatch('auth/AUTH_LOGOUT')
                            router.go('/')
                        } else if (resp.error === 1 && (resp.message === 'Request failed with status code 500' || resp.message === 'Network Error')){
                            resp.error = 2;
                            resp.message = 'Redireccionando flujo de informacion por carga de trabajo, espere un momento';
                        }
                    });
            } else
                resp.message = `Tipo de Solicitud no valida ${type}`;
/**
 * cveError 
 * error = 0 -> ok  
 * error = 1 -> Error
 * error = 2 -> Tiempo de espera agotado (Activar sockets IO) para reanudar operaciones
 * */ 
            return resp;
        }
    }

    // Extraccion de archivos
    async function getFiles(params) {
        let formData = new FormData();
        let dataFiles = []
        if (params != undefined && params.data != undefined && params.data.files != undefined) {
            let files = params.data.files;
            params.data = params.data.data
            let lng = files.length;
            for (let i = 0; i < lng; i++) {
                formData.append(`file${i}`, files[i]);
                let { name, type, size }  = files[i]
                dataFiles[i] = { name, type, size, data : null }
            }
            for( let i = 0; i < lng; i++ )
                dataFiles[i].data = await readFileAsync(formData.get(`file${i}`))
            delete params.data.files
            delete params.data.data
        }
        return dataFiles
    }
    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = () => resolve(new Buffer.from(reader.result,'binary').toString('base64') );
            reader.onerror = reject;
            reader.readAsBinaryString(file);
        })
    }
    
    return API;
})();
export default new API();






import crypto from 'crypto' 
import jsTable from '@/plugins/moduleLib/JsTable'

let _conceptos = {}
let _accessConcepto = {}

const state = {
    token         : localStorage.getItem('auth-token')  || null,
    status        : localStorage.getItem('auth-status') || null,
    user          : JSON.parse(localStorage.getItem('auth-user')) || null,
    overlay       : false,
    perfil        : JSON.parse(localStorage.getItem('routes')) || [],
    sucursales    : JSON.parse(localStorage.getItem('sucursales')) || null,
    action        : null,
    conceptoAct   : null,
}

const getters = {
    isAuthenticated:  ( ) => !!state.token,
    getUser:          ( ) => state.user,
    getStatus:        ( ) => state.status,
    getToken:         ( ) => state.token,
    getPerfil:        ( ) => state.perfil,
    getTipoUsuario:   ( ) => state.user.id_adminCtTipoUsuario,
    getSucursales:    ( ) => state.sucursales,
    getActionProps:   ( ) => searchActionProps(),
    getComponentAccess: () => searchComponentAccess(),
    getIdRlCA:        ( ) => searchIdRlCA()
}

const actions = {
    AUTH_REQUEST: ({commit}) => {
        commit('AUTH_REQUEST')
    },
    AUTH_SUCCESS: ({commit}, data) => {
        commit('AUTH_SUCCESS', data);
    },
    AUTH_LOGOUT: ({commit}) => {
        commit('AUTH_LOGOUT');
    },
    AUTH_ERROR: ({commit}) => {
        commit('AUTH_ERROR')
    },
    setOverlay({commit},val){
        commit('SET_OVERLAY',val)
    },
    setConcepto({commit}, val) {
        commit('SET_CONCEPTO', val)
    },
    setAction({commit}, val) {
        commit('SET_ACTION', val)
    }
}

const mutations = {
    AUTH_REQUEST: (state) => {
        state.status = 'loading'
        setStorage()
    },
    AUTH_SUCCESS: (state, data) => {
        state.status = 'success'
        setStorage( data )
    },
    AUTH_LOGOUT: (state) => {
        state.status = 'logout'
        setStorage()
    },
    AUTH_ERROR: (state) => {
        state.status = 'error'
        setStorage()
    },
    SET_OVERLAY(state,val){
        state.overlay = val
    },
    SET_CONCEPTO(state, val){
        state.conceptoAct = searchConcepto(val)
        state.action = null
    },
    SET_ACTION(state, val){
        state.action = val
    }
}

const setStorage = ( data = {} ) => {

    let {token , user, perfil, sucursales } = data
    state.token  = token  !== undefined ? token  : null
    state.user   = user   !== undefined ? user   : null
    state.perfil = perfil !== undefined ? perfil : null
    state.sucursales = sucursales !== undefined ? sucursales : null

    if(token !== undefined)
        localStorage.setItem('auth-token', token);
    else
        localStorage.removeItem('auth-token')

    if(user !== undefined)
        localStorage.setItem('auth-user', JSON.stringify(user));
    else
        localStorage.removeItem('auth-user')

    if(perfil !== undefined)
        localStorage.setItem('routes', JSON.stringify(perfil) )
    else
        localStorage.removeItem('routes')

    if(sucursales !== undefined)
        localStorage.setItem('sucursales', JSON.stringify(sucursales) )
    else
        localStorage.removeItem('sucursales')
    
    localStorage.setItem('auth-status', state.status);
}

/**
  * @param { string / int } keyConcepto : identificador del concepto ya sea por id o link
  * @return { int } : identificador del concepto
 */
const searchConcepto = (keyConcepto) => {
    let id  = null
    if (keyConcepto !== undefined) {
        if ( !existConcepto() )
            createConceptos()
        id = _accessConcepto[keyConcepto]
    }
    return id
}

/**
 * Obtencion de valor del registro idRlCA corespondiente segun se defina el setConcepto y setAction
 * @return { int } : idRlCA 
 */
const searchIdRlCA = ( ) => (state.conceptoAct !== null && state.action !== null) ? _conceptos[state.conceptoAct].actions[`${state.action}`].idRlCA : undefined 

/**
 * Obtencion de las restricciones del concepto actual segun se definio en 'setConcepto' y
 * en 'setAction' 
 * @return { Object } : Propiedades asignadas  al concepto actual 
 */
const searchActionProps = ( ) => state.conceptoAct !== null && state.actions !== null ? _conceptos[state.conceptoAct].actions[state.action] : undefined

/**
 * Test para verificar la existencia de conceptos
 * @return { bool } : en caso existir true
 */
const existConcepto = () => Object.keys(_conceptos).length !== 0

/**
 * Creacion de conceptos, estos mediante la definicion en el perfil del usuario
 * @return { void } creacion de conceptos
 */
const createConceptos = () => {
    let maxModulo    = 0
    let maxSideMenu  = 0
    let maxConceptos = 0
    const perfil = getters.getPerfil()
    maxModulo = perfil.length
    for(let w = 0; w < maxModulo; w++){ 
        //? modulos
        maxSideMenu = perfil[w].sideMenu.length
        for(let x=0; x < maxSideMenu; x++){
            //? sideMenu
            maxConceptos = perfil[w].sideMenu[x].conceptos.length
            for(let y=0; y < maxConceptos; y++){
                //? conceptos
                const { id, link, actions, format, name } = perfil[w].sideMenu[x].conceptos[y]
                _accessConcepto[`${link}`] = id
                _accessConcepto[`${id}`] = id
                _conceptos[`${id}`] = { actions, format, name }
            }
        }
    }
}

/**
 * Busqueda de los componentes a los que se tiene acceso restringido
 * @return {Object} : Listado de componentes a los que se tiene acceso restringido 
 *  true : 'Permitido' 
 *  false: 'Restringido'
 */
const searchComponentAccess = () =>{
    let components = {}
    let props      = null
    try{
        props = searchActionProps()
        if(props.rest !== undefined ? props.rest.components !== undefined : false) 
            jsTable.toObj(props.rest.components).forEach(( element ) => components[`${element.nm}`] = element.rl !== 'H' )
    }catch(e){
        console.log(`searchComponentAccess : ${e}`)
    }
    return components
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

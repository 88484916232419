import Vue from 'vue'
const state = {
    drawer: false,
    icons : {}
}

const getters = {
    getActionsTable() {
        return state.icons;
    }
}

const actions = {
    setVisibility( { commit }, val) {
        commit('SET_VISIBILITY',val)
    },
    setActionsTable( { commit }, val) {
        commit('SET_ACTIONS_TABLE',val)
    },
}

const mutations = {
    SET_VISIBILITY(state,val) {        
        state.drawer = val;
    },
    SET_ACTIONS_TABLE( state, data ) {
        state.icons = data;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}


const JsTable = {
    toObj(table){
        try {
            if ( Object.keys(table).length == 0 )
                throw 'objecto vacio'
            const { Data, ColsName } = table                
            if( Data == undefined || ColsName == undefined) 
                throw 'estructura incorrecta'
            let result = [],
                obj    = {};                
            result = Data.map(data => {
                obj = {}
                ColsName.forEach((name, i) =>  obj[name] = data[i] )
                return obj
            })                
            return result   
        } catch (e) {            
            console.error(e);
        }            
    },
    getFormat( tabla, tipo, obj, keys = [] ) {
        try {
            let result   = Object();
            let reg      = null;
            let x        = 0;
            let _KeySize = keys.length;
            let data     = [];

            result.Name = tabla;
            result.Action = tipo;
            let max = obj.length;
            if (obj instanceof Array) {
                do {
                    reg = obj[x];
                    x++;
                } while ((reg === null || typeof reg === "undefined") && x < max);
            }
            else
                reg = obj;
            result.ColsName = getColsName(tabla, reg, keys);
            obj.forEach(element => { let aux = getData(result.ColsName, element, tipo); aux != null ? data.push(aux) : "" });
            if (data !== null)
                result.Data = data;
            else
                result.Data = Array();
            if (_KeySize > 0)
                result.KeySize = _KeySize;
            return result;
        } catch (e) {
            console.error(e);
        }
    }
}

export default JsTable;

function getColsName(table, obj, _keys) {
    let arr;
    if (_keys.length > 0)
        arr = _keys;
    else
        arr = Array("id_" + table);
    for (let i in obj) {
        if (obj.hasOwnProperty(i)) {
            if (!existeCol(i, arr))
                arr[arr.length] = i;
        }
    }
    return arr;
}
function existeCol(name, colsName) {
    let max = colsName.length;
    for (let x = 0; x < max; x++) {
        if (name.localeCompare(colsName[x]) == 0)
            return true;
    }
    return false;
}
function getData(colsName, obj, tipo) {
    if (obj !== null ? (typeof obj !== "undefined" ? true : false) : false) {
        let arr = Array();
        let max = colsName.length;
        if (tipo.localeCompare("Update") === 0) {
            for (let i = 0; i < max; i++) {
                if (obj.hasOwnProperty(colsName[i]))
                    arr.push([i, obj[colsName[i]]]);
            }
        }
        else if (tipo.localeCompare("Insert") === 0) {
            for (let i = 0; i < max; i++) {
                if (obj.hasOwnProperty(colsName[i]))
                    arr.push(obj[colsName[i]]);
                else
                    arr.push(null);
            }
        }
        else
            console.error("Tipo no definido " + tipo);
        return arr;
    }
    else
        return null;
}
import Vue     from "vue";
const state = {
    dataSource : [],
    dataAsignacion : {
        responsable : 0,
        auxiliar    : 0,
        equipoAdic  : 0,
        instruccion : "",
        idTrTicket  : -1,
        estatus     : ""
    }
}
const getters = {
    getDataSource: (state) => {
        return state.dataSource
    },
    getDataAsignacion : ( state ) => {
        return state.dataAsignacion
    }
}
const actions = {
    setDataSource( {commit}, data ){
        commit("SET_DATASOURCE", data)
    },
    setDataAsignacion({commit},config){
        commit("SET_DATA_ASIGNACION",config)
    },
}
const mutations = {
    SET_DATASOURCE(state, source ){
        state.dataSource = source
    },
    SET_DATA_ASIGNACION(state, {
        key,
        data
    }){
        Vue.set(state.dataAsignacion, key, data);  
    }
}   
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

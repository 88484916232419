/** 
 * @namespace Msg
 * @name Msg
 * @module Msg
 * @fileOverview Various tool functions.
 * @author Luis López
 * @version 1.1
 */
import Vue     from "vue";
const Msg = ( () => {
    // ? Instancia de Vue
    const _vue = new Vue;

    /**
     * @function
     * @name success
     * @param { string } msg Mensaje a mostrar
     * @description Muestra un mensaje Swal de tipo success
     * @return retorna el objeto Swal
     */
    const _success = msg => {(
        _vue.$swal({ 
            title            : 'Éxito',
            text             : msg,
            type             : 'success',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        })
    )} 

    /**
     * @function
     * @name error
     * @param { string } msg Mensaje a mostrar
     * @description Muestra un mensaje Swal de tipo error
     * @return retorna el objeto Swal
     */
    const _error = msg => 
        _vue.$swal({ 
            title            : 'error',
            text             : msg,
            type             : 'error',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        })
    
    /**
     * @function
     * @name warning
     * @param { string } msg Mensaje a mostrar
     * @description Muestra un mensaje Swal de tipo warning
     * @return retorna el objeto Swal
     */
    const _warning = msg => 
        _vue.$swal({ 
            title            : 'Precaución',
            text             : msg,
            type             : 'success',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        })

    /**
     * @function
     * @name info
     * @param { string } msg Mensaje a mostrar
     * @description Muestra un mensaje Swal de tipo info
     * @return retorna el objeto Swal
     */
    const _info = msg => 
        _vue.$swal({ 
            title            : 'Información',
            text             : msg,
            type             : 'info',
            confirmButtonText: 'Aceptar',
            allowOutsideClick: false
        })

    /**
     * @function
     * @name confirma
     * @param { string } msg Mensaje a mostrar
     * @param { Object } values Valores de configuración
     * @description Muestra un mensaje Swal de tipo question
     * @return retorna el objeto Swal
     */
    const _confirma = (
        msg,
        values = { type:"question",title:'Confirmación',cancel:true },

    ) => 
        _vue.$swal({ 
            title            : values.title,
            text             : msg,
            type             : values.type,
            confirmButtonText: 'Sí',
            cancelButtonText : 'No',
            showCancelButton : values.cancel,
            allowOutsideClick: false
        })     

    /**
     * @function
     * @name server
     * @param { Object } data Respuesta del servidor
     * @description Evalua la respuesta del servidor para comportamiento simple 
     * @return booleano de acuerdo a la respuesta del servidor
     */
    const _server = data => {
        let res = false;

        switch (data.error) {
            case 0:
                res = true
                break;
            case 2:
                _info(data.message)
                break;
            default:
                _error(data.message)
                break;
        }
        return res
    }    

    /**
     * @function
     * @name fn
     * @param { Object } data Respuesta del servidor
     * @description Evalua la respuesta del servidor para comportamiento complejo 
     * @return booleano de acuerdo a la respuesta del servidor
     */
    const _fn = ({
        data,
        message
    }) => {
        let res = false
        if ( data.error === 0 ) {            
            _success( _getMessage(message) );
            res = true;
            _frmFn._closeDialogs();
        } else
            _frmFn._showError(data);
        return res 
    }

    /**
     * @function
     * @name frmFn
     * @description Clase formulario 
     */
    const _frmFn = ( () => {        
        var _stateDialogs = []
        const frmActions = {
            setDialog     : fn => _stateDialogs.push(fn),
            _closeDialogs : () => {
                if ( _stateDialogs.length > 0){
                    _stateDialogs.forEach( dialogFn => dialogFn(false) );                    
                    _stateDialogs = [];
                }
            },
            _showError : data =>  {
                const { error, message } = data
                switch (error) {
                    case 1: 
                        _error(message)
                        break;
                    case 2: 
                        _error(message)
                        break;
                    case 3: 
                        _error(message)
                        break;
                    case 4: 
                        _error(message)
                        break;
                    case 5: 
                        _error(message)
                        break;
                    default: 
                        console.error("error no encontrado");
                        break;
                }
                console.error(message);
            }
        }
        return frmActions
    })()

    /**
     * @function
     * @name input
     * @description Muestra un mensaje Swal con un input de tipo text
     * @return retorna el objeto Swal
     */
    const _input = () => 
        _vue.$swal({ 
            title               : 'Nombre del reporte',
            input               : 'text',
            inputPlaceholder    : 'reporte_sir',
            showCloseButton     : true,
            showCancelButton    : true,
            confirmButtonText   : 'Generar'
        })

    /**
     * @function
     * @name textarea
     * @description Muestra un mensaje Swal con un textarea de tipo text
     * @return retorna el objeto Swal
     */
    const _textarea = (
        msg,
        values = { title : 'Observaciones', placeHolder : 'Observaciones', btnConfirm : 'Enviar' }
    ) => 
        _vue.$swal({ 
            title               : values.title,
            text                : msg, 
            input               : 'textarea',
            inputPlaceholder    : values.placeHolder,
            showCloseButton     : true,
            showCancelButton    : true,
            confirmButtonText   : values.btnConfirm,
            inputValidator : ( value ) => {
                if( !value )
                    return 'Campo obligatorio'
            }
        } )
    
    

    /*----------  Private functions  ----------*/        
    const _getMessage = msg => _messages.hasOwnProperty(msg) ? `Registro ${_messages[msg]} correctamente` : msg;

    const _messages = {
        c    : "creado",
        u    : "actualizado",
        a    : "activado",
        i    : "desactivado",
        d    : "eliminado",
        auth : "autorizado",
        dAuth: "desautorizado",
        can  : "cancelado",
        cot  : "cotizado",
        ap   : "aplicado",
        con  : "contabilizado",
        cer  : "cerrado",
        aju  : "ajustado"
    }   
    return {
        confirma     : _confirma,
        success      : _success,
        error        : _error,
        warning      : _warning,
        info         : _info,
        server       : _server,
        fn           : _fn,
        frmSetDialog : _frmFn.setDialog,
        input        : _input,
        textarea     : _textarea
    }        
})() 

export default Msg
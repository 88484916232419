import  FormularioLogin  from "./components/IndexLoginFormulario.vue";
import { mapState } from 'vuex'
export default {
    computed:{
        ...mapState({
            overlay : state => state.auth.overlay
        })   
    },
    components : {
        FormularioLogin
    }
}
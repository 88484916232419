import Vue     from "vue";
const state = {
    // ? Objeto para el guardado de información de los formularios
    forms         : {},
    defaultValues : {}
}

const getters = {

}

const actions = {
    setFrm({ commit }, payload ){
        commit("SET_FRM", payload)
    },
    setInitValueFrm({ commit }, payload ){

        commit("SET_INIT_VALUE_FRM", payload )

        commit("SET_DEFAULT_VALUE", payload )
        
    },  
    setValueFrm ( { commit }, payload ){

        commit("SET_VALUE_FRM", payload )

        commit("SET_VALUE_FRM", {
            frm  : payload.frm,
            key  : "valid",
            data : true
        })
    },
    restoreFrmDefault( { commit }, payload ) {        
        var frmValues = state.forms[payload];
        for (const key in frmValues) {
            commit("SET_VALUE_FRM", {
                frm  : payload,
                key  : key,
                data : state.defaultValues[key]
            })
        }        
    }
}

const mutations = {
    /**
     * @description Inicializa el objeto forms
     * @param {string} frm Propiedad para state forms     
     */
    SET_FRM(state, frm ){
        Vue.set(state.forms, frm, {});  
    },
    /**
     * @description Inicializa el objeto forms con la propiedad 
     * indicada para guardar el valor de un componente
     */
    SET_INIT_VALUE_FRM(state, {
        frm,
        key,
        defaultValue
    }){
        Vue.set(state.forms[frm], key, defaultValue); 
    },
    /**
     * @description Establece un valor por defecto para una propiedad de 
     * un componente
     */
    SET_DEFAULT_VALUE(state, {
        key,
        defaultValue
    }){
        Vue.set(state.defaultValues, key, defaultValue); 
    },
    /**
     * @description Guarda el valor en el formulario indicado 
     * y con la propiedad del componente
     */
    SET_VALUE_FRM(state, {
        frm,
        key,
        data
    }){
        Vue.set(state.forms[frm], key, data); 
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
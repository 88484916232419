import { store } from '@/store'
import api from '@/services/api';
const setApi = parametros => {
    var funcs = {}
    parametros.request.forEach( r => {
        let { modulo, seccion, accion } = parametros
        let servicio = (parametros.abrev + parametros.accion + "_" +r )
        funcs[servicio] = (data, idRlCA = getIdRlCA()) => api.get('/', { modulo, seccion, accion, servicio, idRlCA, data }, 'POST')
    })
    // console.log(funcs);
    return funcs
}

const getIdRlCA = () =>  store !== undefined ? store.getters['auth/getIdRlCA'] : null

export default setApi
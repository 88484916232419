import Vue     from "vue";
const state = {
    dataSource : [],
    dataRender : {
        usuario     : [],
        sucursal    : [],
        servicio    : [],
        evento      : [],
        estatus     : [],
        responsable : []
    },
    dataSelected : {
        usuario     : undefined,
        sucursal    : undefined,
        servicio    : undefined,
        evento      : { id : 0, name: "", description : "" },
        estatus     : undefined,
        responsable : undefined
    }
   
}
const getters = {
    getDataSource: (state) => {
        return state.dataSource
    },
    getDataTicket:(state) => {
        return state.dataRender;
    }
}
const actions = {
    setDataRender({commit},config){
        commit("SET_DATA_RENDER",config)
    },
    setDataSelected({commit},config){
        commit("SET_DATA_SELECTED",config)
    },
    setDataSource( {commit}, data ){
        commit("SET_DATASOURCE", data)
    }
}
const mutations = {
    SET_DATA_RENDER(state, {
        key,
        data
    }){
        Vue.set(state.dataRender, key, data);  
    },
    SET_DATA_SELECTED(state, {
        key,
        data
    }){
        Vue.set(state.dataSelected, key, data);  
    },
    SET_DATASOURCE(state, source ){
        state.dataSource = source
    }
}   
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

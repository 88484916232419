import Vue       from 'vue'
import VueRouter from 'vue-router'
import Index     from '../views/IndexLogin/IndexLogin.vue'
import Logout    from '../views/IndexLogin/IndexLogin.vue'
import NotFound  from '../views/pages/404.vue'

import {setDynamicRoute} from './dynamicRoutes.js'
import authStore from '../store/modules/authStore'

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
  // document.getElementById("msg").innerHTML = `${authStore.getters.isAuthenticated()}`
  if (!authStore.getters.isAuthenticated())
    next()
  next('/home')
}

const ifAuthenticated = (to, from, next) => {
  if (authStore.getters.isAuthenticated()) {
    next()
    return
  }
  next('/')
}

var routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,    
    beforeEnter : ifNotAuthenticated
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import("../views/Home/Home.vue"),
    props : {
        par : 1 
    },
    beforeEnter: ifAuthenticated
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
    beforeEnter: ifAuthenticated
  },
  { path: '*', component: NotFound }
]

setDynamicRoute(routes)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;

import Vue from "vue";
const state = {
    dataSource: [],
    dataSlider: [],
    slider: 1,
    dataRender: {
        sucursales: [],
        zonas: [],
        zonasDet: [],
        rubros: []
    },
    dataSelected: {
        sucursales: [],
        zonas: [],
        zonasDet: [],
        rubros: []
    },
    margen: 0,
}
const getters = {
    getContTicketCreado: (state) => {
        return setContTicketCreado(state);
    },
    getContTicketCerrado: (state) => {
        return setContTicketCerrado(state);
    },
    getContTicketNoResuelto: (state) => {
        return setContTicketNoResuelto(state);
    },
    getContTicketPausado: (state) => {
        return setContTicketPausado(state);
    },
    getDataCerradosNoResuelto: (state) => {
        return Object.values(setDataCerradosNoResuelto(state))
    },
    getDataCalificacionUsuario: (state) => {
        return Object.values(setDataCalificacionUsuario(state))
    },


    getDataTopTipos: (state) => {
        return Object.values(setDataTopTipos(state))
    },
    getDataTopSucursales: (state) => {
        return Object.values(setDataTopSucursales(state))
    },
    getDataRegistrosSucursal: (state) => {
        return Object.values(setDataRegistrosSucursal(state))
    },
    getDataCerradosResponsable: (state) => {
        return Object.values(setDataCerradosResponsable(state))
    },
    getDataTopResponsable: (state) => {
        return Object.values(setDataTopResponsable(state))
    }
}
const actions = {
    setDataSource({
        commit
    }, data) {
        commit("SET_DATASOURCE", data)
        commit("SET_DATA_SLIDER", getDataSlider(data, state.slider[0] - 1, state.slider[1]))
    },
    setDays({
        commit
    }, payload) {
        commit("SET_DAYS", payload)
        commit("SET_DATA_SLIDER", getDataSlider(state.dataSource, payload[0] - 1, payload[1]))
    }
}
const mutations = {
    SET_DATASOURCE(state, source) {
        state.dataSource = source
    },
    SET_DAYS(state, days) {
        state.slider = days;
    },
    SET_DATA_SLIDER(state, dataSlider) {
        state.dataSlider = dataSlider
    },
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
/**
 * @param  {[]} state datos provenientes de peticion VenR_getSegRentabilidad
 * @returns {[]} formato requerido para tabla Renta general rubro 
 */

const setContTicketCreado = (state) => { // ?? Se regresa la cantidad de tickets el ultimo día seleccionado en el slider
    let creados = 0;
    if (state.dataSlider.length > 0) {
        state.dataSlider.forEach(item => {
            if (item.id_baseCtEstatus == 1 && item.dia <= state.slider[1])
                creados++;
        });
    }
    return creados;
}
const setContTicketCerrado = (state) => { // ?? Se regresa la cantidad de tickets el ultimo día seleccionado en el slider
    let cerrados = 0;
    if (state.dataSlider.length > 0) {
        state.dataSlider.forEach(item => {
            if (item.id_baseCtEstatus == 5 && item.dia <= state.slider[1])
                cerrados++;
        });
    }
    return cerrados;
}
const setContTicketNoResuelto = (state) => { // ?? Se regresa la cantidad de tickets el ultimo día seleccionado en el slider
    let noResuelto = 0;
    if (state.dataSlider.length > 0) {
        state.dataSlider.forEach(item => {
            if ([2, 3, 4].includes(item.id_baseCtEstatus) && item.dia <= state.slider[1])
                noResuelto++;
        });
    }
    return noResuelto;
}
const setContTicketPausado = (state) => { // ?? Se regresa la cantidad de tickets el ultimo día seleccionado en el slider
    let pausado = 0;
    if (state.dataSlider.length > 0) {
        state.dataSlider.forEach(item => {
            if (item.id_baseCtEstatus == 7 && item.dia <= state.slider[1])
                pausado++;
        });
    }
    return pausado;
}
const setDataCerradosNoResuelto = (state) => {
    let arrData = [{
        estatus: 'Cerrado',
        area: 0
    }, {
        estatus: 'No resuelto',
        area: 0
    }, {
        estatus: 'No atendido',
        area: 0
    }]
    if (state.dataSlider.length > 0) {
        state.dataSlider.forEach(item => {
            if (item.id_baseCtEstatus == 5) // ? CERRADO => 5 => Cerrado
                arrData.find(data => {
                    if (data.estatus == 'Cerrado') data.area++;
                })
            else if ([3, 4, 7].includes(item.id_baseCtEstatus)) // ?? NO RESUELTO => 3 => Atendiendo || 4 => Finalizado || 7 => Pausado
                arrData.find(data => {
                    if (data.estatus == 'No resuelto') data.area++;
                })
            else if ([1, 2].includes(item.id_baseCtEstatus)) // ?? NO ATENDIDO => 1 => Documentado || 2 => Asignado
                arrData.find(data => {
                    if (data.estatus == 'No atendido') data.area++;
                })
        });
    }
    return arrData;
}

const setDataCalificacionUsuario = (state) => {
    let arrData = [{
        stars: '1',
        cont: 0
    }, {
        stars: '2',
        cont: 0
    }, {
        stars: '3',
        cont: 0
    }, {
        stars: '4',
        cont: 0
    }, {
        stars: '5',
        cont: 0
    }];
    if (state.dataSlider.length > 0) {
        let data;
        state.dataSlider.forEach(item => {
            if (item.id_baseCtEstatus == 5) { // ? CERRADO => 5 => Cerrado
                data = arrData.find(element => element.stars == item.calificacion)
                if (data) {
                    data.cont++;
                }
            }
        });
    }

    return arrData;
}
const setDataTopTipos = (state) => {
    let dataCalificacion = [];
    if (state.dataSlider.length > 0) {
        let data;
        state.dataSlider.forEach(item => {
            // if( item.id_baseCtEstatus == 5 ) {    // ? CERRADO => 5 => Cerrado
            data = dataCalificacion.find(element => element.categoria == item.categoria)
            if (data) {
                data.incidencias++;
            } else {
                dataCalificacion.push({
                    categoria: item.categoria,
                    incidencias: 1
                })
            }
            // }
        });
    }
    dataCalificacion.sort((a, b) => compare(a.incidencias, b.incidencias))
    return dataCalificacion.slice(0, 5);
}

const compare = (a, b) => {
    let x = 0;
    if (a > b)
        x = 1;
    if (a < b)
        x = -1;
    return x;
}

const setDataTopSucursales = (state) => {
    let dataCalificacion = [];
    if (state.dataSlider.length > 0) {
        let data;
        state.dataSlider.forEach(item => {
            if (item.id_baseCtEstatus == 5) { // ? CERRADO => 5 => Cerrado
                if (item.calificacion) {
                    data = dataCalificacion.find(element => element.categoria == item.categoria)
                    if (data) {
                        data.incidencias++;
                        data.calificacion += item.calificacion;
                        data.promedio = data.calificacion / data.incidencias;
                    } else {
                        dataCalificacion.push({
                            categoria: item.sucursal,
                            calificacion: item.calificacion,
                            incidencias: 1,
                            promedio: item.calificacion
                        })
                    }
                }
            }
        });
    }
    dataCalificacion.sort((a, b) => compare(a.promedio, b.promedio))
    return dataCalificacion.slice(0, 5);
}

const setDataRegistrosSucursal = (state) => {
    let dataCalificacion = [];
    if (state.dataSlider.length > 0) {
        let data;
        state.dataSlider.forEach(item => {
            data = dataCalificacion.find(element => element.sucursal == item.sucursal)
            if (data) {
                if ([3, 4, 7].includes(item.id_baseCtEstatus)) // ?? NO RESUELTO => 3 => Atendiendo || 4 => Finalizado || 7 => Pausado
                    data.noResuelto++;
                if ([1, 2].includes(item.id_baseCtEstatus)) // ?? NO ATENDIDO => 1 => Documentado || 2 => Asignado
                    data.noAtendido++;
            } else {

                dataCalificacion.push({
                    sucursal:    item.sucursal,
                    noAtendido:  [1, 2].includes(item.id_baseCtEstatus) ? 1 : 0,
                    noResuelto:  [3, 4, 7].includes(item.id_baseCtEstatus) ? 1 : 0
                })
            }
        });
    }
    return dataCalificacion;
}
const setDataCerradosResponsable = (state) => {
    let dataCerrados = [];
    if (state.dataSlider.length > 0) {
        let data;
        state.dataSlider.forEach(item => {
            if( item.id_baseCtEstatus == 5 ) {    // ? CERRADO => 5 => Cerrado
                data = dataCerrados.find(element => element.name == item.name.split(" ")[0] )
                if (data) {
                    data.incidencias++;
                } else {
                    dataCerrados.push({
                        name       : item.name.split(" ")[0],
                        incidencias: 1
                    })
                }
            }
        });
    }
    dataCerrados.sort((a, b) => compare(a.incidencias, b.incidencias))
    return dataCerrados;
}
const setDataTopResponsable = (state) => {
    let topResponsables = [];
    if (state.dataSlider.length > 0) {
        let data;
        state.dataSlider.forEach(item => {
            if (item.id_baseCtEstatus == 5) { // ? CERRADO => 5 => Cerrado
                if (item.name != null) {
                    data = topResponsables.find(element => element.name == item.name.split(" ")[0])
                    if (data) {
                        data.incidencias++;
                        data.calificacion += item.calificacion;
                        data.val = data.calificacion / data.incidencias;
                    } else {
                        topResponsables.push({
                            name        : item.name.split(" ")[0],
                            calificacion: item.calificacion,
                            incidencias : 1,
                            val         : item.calificacion
                        })
                    }
                }
            }
        });
    }
    topResponsables.sort((a, b) => compare(a.val, b.val))
    return topResponsables.slice(0, 5);
}

const getDataSlider = (dataSource, inicio, fin) => {
    let auxSource = dataSource.slice(inicio, fin);
    let data = [];
    auxSource.forEach(source => {
        if (source.dia >= inicio && source.dia <= fin)
            data = [...data, source]
    })
    return data;
}

import {
    mapGetters
} from 'vuex'

export default {
    name: 'FourOhFour',
    methods : {
        ...mapGetters("auth", ["isAuthenticated"]),
    },
    created() {
        console.log(this.isAuthenticated())
    }
}
import Vue     from "vue";
import service from '@/services/base'
const state = {
    dataFrm   : JSON.parse(localStorage.getItem('dataFrm')) || {} ,
    propertys : [],
    loadFrm   : false
}

const getters = {
    getDataFrm: state => {        
        var data = {};
        state.propertys.forEach( property => {
            if( state.dataFrm.hasOwnProperty(property)  )
                data[property] = state.dataFrm[property]
        })
        return data
    }
}

const actions = {
    /**
     * @description Realiza la petición o consulta de información para formulario
     * @param  {string[]} propertyServer
     */
    async setDataFrmFromServer( {commit }, propertyServer ) {
        var propertyLocal = [],
            frmData       = {},
            dataFrm       = JSON.parse(localStorage.getItem('dataFrm')) || state.dataFrm

        commit("SET_OVERLAY", true);
        
        propertyServer.forEach( property => {

            if ( !dataFrm.hasOwnProperty( property ) )                
                propertyLocal.push( property )     

            if(!state.propertys.includes(property))
                commit( "SET_PROPERTIES", property ); 
        });
        
        if( propertyLocal.length > 0 ){
            await service.BasR_getFrmInit(propertyLocal).then( data => {
                frmData = data.data
                for (const key in frmData) {
                    commit("SET_DATAFRM", { data : frmData[key], property :key } )
                }
            })            
        }    
        
        commit("SET_OVERLAY", false);
    }
}

const mutations = {
    SET_DATAFRM( state, { data, property } ){        
        Vue.set(state.dataFrm, property, data);        
        localStorage.setItem('dataFrm', JSON.stringify(state.dataFrm));
    },
    SET_PROPERTIES( state, payload){
        state.propertys = [...state.propertys, payload]
    },
    SET_OVERLAY( state, payload ){
        state.loadFrm = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
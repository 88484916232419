'use strict'

import Vue              from   'vue'
import Vuex             from   'vuex'
import appbar           from './modules/appbar'
import home             from './modules/home'
import auth             from './modules/authStore'
import slider           from './modules/dataSlider'

import initFrm          from './modules/reportesInitFrmStore'
import FormStore        from './modules/Forms/FormStore'

import Servicio         from './modules/Soporte/ServicioStore'
import categoria        from './modules/Soporte/CategoriaStore'
import Evento           from './modules/Soporte/EventoStore'
import Requerimiento    from './modules/Soporte/RequerimientoStore'
import Ticket           from './modules/Soporte/TicketStore'
import Asignacion       from './modules/Soporte/AsignacionStore'
import dashboard        from './modules/Soporte/DashboardStore'
import User             from './modules/Administracion/UserStore'
import DialogForm       from './modules/Forms/DialogFormStore'

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules :{
        appbar,
        home,
        auth,
        slider,
        initFrm,
        FormStore,
        Servicio,
        categoria,
        Evento,
        Requerimiento,
        Ticket,
        Asignacion,
        dashboard,
        DialogForm,
        User
    },
    state: {
        
    },
    actions :{
        
    },
    mutations: {
        
    }
})

import { store } from '../store'

const dynamicRoute = route => {
    var path = `/${route.concepto.link}`
    var routeSet = {
        path : path,
        name : `${route.concepto.link}`,
        props: { 
            dataConcepto : {
                mod : {
                    id : route.mod.id,
                    name : route.mod.name
                },
                sideMenu : {
                    id : route.sideMenu.id,
                    name : route.sideMenu.name
                },
                concepto : {
                    id : route.concepto.id,
                    name : route.concepto.name
                }
            }
        },
        component : () => import(`../views${path}.vue`),
        beforeEnter: (to, from, next) => {
            store.dispatch('auth/setConcepto', route.concepto.id)
            next()
        }
    }
    return routeSet
}

const setDynamicRoute = routes => {
    let local = store.getters['auth/getPerfil']
    local.forEach(mod => {
        mod.sideMenu.forEach( menu => {
            menu.conceptos.forEach(concepto => {
                if( routes.find( el => el.name != concepto.name ) )    
                    routes.push( dynamicRoute( { mod : mod, sideMenu : menu, concepto : concepto  } ) )        
            });
        });
    });
}

export {
    setDynamicRoute
}
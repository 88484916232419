import Vue     from "vue";
const state = {
    // ? Objeto para el guardado de booleanos para apertura de multiples dialogs
    dialogs       : {}
}

const getters = {

}

const actions = {
    setNameDialog( { commit }, payload ){
        commit("SET_NAME_DIALOG", payload)
    },
    setDialog( { commit }, payload ){
        commit("SET_DIALOG", payload)
    }
}

const mutations = {
    SET_NAME_DIALOG(state, dialog ){
        Vue.set(state.dialogs, dialog, { status : false });  
    },
    SET_DIALOG(state,{
        key,
        status
    }){
        state.dialogs[key].status = status
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
import authService    from '@/services/authService'
import { mapActions } from 'vuex'

export default {
    data: () => ({
        show        : false,
        valid       : true,
        usuarioRules: [
            v => !! v || 'Campo requerido',
            v => (v && v.length <= 20) || 'Campo menor a 20 caracteres',
        ],
        passwRules: [
            v => !!v || 'Campo requerido',
        ],
        userName : '',
        password : '',
        error    : ''
    }),
    methods: {
        ...mapActions('auth',["setOverlay"]),
        ...mapActions("home",["setElements"]),
        async validate() {            
            if( this.$refs.form.validate() ) {
                try {
                    this.setOverlay(true)
                    this.$store.dispatch('auth/AUTH_REQUEST')
                    this.error = '';
                    const { userName, password } = this
                    let response = await authService.login({ userName, password });
                    let { error, message, data} = response
                    
                    if (error === 0){
                        this.$store.dispatch('auth/AUTH_SUCCESS', data)
                        this.userName = ''
                        this.password = ''
                                            
                        this.$router.go('/home');
                    }else{
                        this.$store.dispatch('auth/AUTH_ERROR')                                            
                        this.$swal({ 
                            title: 'Error',
                            text: 'Usuario y/o contraseña incorrecta',
                            type: 'error',
                            confirmButtonText: 'Aceptar'}
                        );
                    }
                    this.error = message
                    this.setOverlay(false)
                }catch( e ){
                    this.error = e
                }
            }
       },
        async logout(){
            try{
                let result = await authService.logout();
                let {message} = result;
                //!fix eliminado de encabezado de Authorization
                this.$store.dispatch('auth/AUTH_LOGOUT')
                this.error = message
                this.$router.go('/');
            } catch (e) {
                this.error = e;
            }
        }
    },
    created() {
        if ( this.$store.getters['auth/isAuthenticated'] && this.$route.name == `Logout`) 
            this.logout();        
        
    }
}
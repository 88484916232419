import Vue      from 'vue'
import App      from './App.vue'
import router   from './router'
import {store}  from './store'
import vuetify  from './plugins/vuetify';
import VueSweetalert2 from 'vue-sweetalert2';
import VueMq from 'vue-mq'

import ModuleLib from '@/plugins/moduleLib/index.js'
import 'sweetalert2/dist/sweetalert2.min.css';

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.teal.light.compact.css';

// import VueSocketIO from './libs/socketIO'

// const configIO = {
//     connection: 'https://api.desarrollo-web.mx',
//     vuex: Vue,
//     debug: false,
//     options: { 
//         path: '/socket.io/' ,
//         useConnectionNamespace : true,
//         namespaceName : 'REP'
//     }
// }

// const socket_io = new VueSocketIO(configIO)

// socket_io.install(Vue)

Vue.use(VueSweetalert2);

Vue.use(VueMq, {
    breakpoints: {
        sm: 450,
        md: 1250,
        lg: Infinity,
    },
    defaultBreakpoint: 'sm' // customize this for SSR
  })

Vue.use(ModuleLib); 

Vue.config.productionTip = false

Date.prototype.getMonthName = function(lang) {
    lang = lang && (lang in Date.locale) ? lang : 'en';
    return Date.locale[lang].month_names[this.getMonth()];
};

Date.prototype.getMonthNameShort = function(lang) {
    lang = lang && (lang in Date.locale) ? lang : 'en';
    return Date.locale[lang].month_names_short[this.getMonth()];
};

Date.prototype.getMonths = function(lang) {
    lang = lang && (lang in Date.locale) ? lang : 'en';
    return Date.locale[lang].month_names;
};

Date.locale = {
    en: {
       month_names: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
       month_names_short: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic']
    }
};

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

import Vue     from "vue";
const state = {
    dataSource : [],
   
}
const getters = {
    getDataSource: (state) => {
        return state.dataSource
    },
}
const actions = {
    setDataSource( {commit}, data ){
        commit("SET_DATASOURCE", data)
    }
}
const mutations = {
    SET_DATASOURCE(state, source ){
        state.dataSource = source
    }
}   
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

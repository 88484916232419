const functions = ( () => {
    var _idDocMod = 0;
    /**
     * 
     * @param {* Objeto Base para comparacion} a 
     * @param {* Objeto a comparar para diferencias} b 
     * @param {* cadena o arreglo de registros que se agregan de manera predeterminada solo para el primer nivel } toDiff 
     * @return {* Objeto / entero }
     *          Objeto: En caso de que existan diferencias entre los dos objetos
     *          Entero:  0 -> para cuando son iguales
     *                  -1 -> los elementos a comparar no son iguales en cuanto a estructura
     *                  -2 -> Comparacion no permitida por lo regular si los tipos de datos son diferentes
     */
    const _getDiffObj = (a, b, toDiff = []) => {
        let NOT_ALLOWED = -2
        let NOT_EQUAL = -1;
        let EQUAL = 0;
        let aType = typeof a,
            bType = typeof b;
        analisis: {
            if (aType !== bType) {
                console.error("Ambos elementos no son del mismo tipo");
                return NOT_EQUAL;
            }
            if (a === b) {
                if (aType === 'object' && a !== null)
                    return EQUAL; // es el mismo objeto
                console.error("Tipo de dato no valido");
                return NOT_ALLOWED;
            }
            if (a.constructor.name == 'Object') {
                let aKey = Object.getOwnPropertyNames(a);
                let bKey = Object.getOwnPropertyNames(b);
                let max = aKey.length;
                let elementA, elementB;
                let result;
                let obj = {};
                aKey.sort();
                bKey.sort();
                for (let x = 0; x < max; x++) {
                    elementA = a[aKey[x]];
                    elementB = b[aKey[x]];
                    if (isToDiff(aKey[x], toDiff) === true) {
                        elementB = elementA;
                        result = false;
                    } else {
                        if (typeof elementB !== 'undefined') {
                            result = compare(elementA, elementB, toDiff);
                        } else
                            result = true;
                    }
                    if (typeof result === 'boolean' ? !result : false)
                        obj[aKey[x]] = elementB;
                    else if (typeof result === 'object')
                        obj[aKey[x]] = result;
                }
                max = bKey.length;
                for (let x = 0; x < max; x++) {
                    if (typeof a[bKey[x]] === 'undefined')
                        obj[bKey[x]] = b[bKey[x]];
                }

                if (Object.keys(obj).length > 0) {
                    let status = true;
                    if (toDiff.length > 0) {
                        aKey = Object.getOwnPropertyNames(obj);
                        let max = aKey.length;
                        status = false;
                        for (let x = 0; x < max; x++) {
                            if (!isToDiff(aKey[x], toDiff)) {
                                status = true;
                                break;
                            }
                        }
                    }
                    if (status)
                        return obj;
                }
                return EQUAL;
            } else if (a.constructor.name == 'Array') {
                let max = a.length > b.length ? a.length : b.length;
                let result;
                let arr = [];
                let numUndefined = 0;
                let numDif = 0;
                for (let x = 0; x < max; x++) {
                    result = compare(a[x], b[x], toDiff);
                    if (typeof result === 'boolean') {
                        if (!result) {
                            arr[x] = b[x];
                            if (typeof arr[x] === 'undefined')
                                numUndefined++;
                            else
                                numDif++;
                        } else {
                            arr[x] = undefined;
                            numUndefined++;
                        }
                    } else if (typeof result === 'object') {
                        numDif++;
                        arr[x] = result;
                    }
                }
                if (numUndefined <= a.length && numDif == 0)
                    return EQUAL;
                else if (arr.length > 0)
                    return arr;
            } else {
                console.error("Tipo de datos no permitido");
                return NOT_ALLOWED;
            }
        }
    }

    const _setIdDocMod = id => _idDocMod = id;
    const _getIdDocMod = () => _idDocMod;
    
    const _getTableDtFlujo = ( idOrigen, idEstatus ) => {
        let objInfo = {
            id_baseDtFlujoEstatus: -1,
            id_docOrigen         : idOrigen,
            id_baseCtEstatus     : idEstatus,
            id_baseCtDocModulo   : _idDocMod,
            activo               : 1
        }
        return objInfo
    }

    const _getTableDsFlujo = ( observacion ) => {
        let objInfo = {
            id_baseDsFlujoEstatus : -1,
            id_baseDtFlujoEstatus : -1,
            descripcion           : observacion,
            activo                : 1
        }
        return objInfo
    }
    
    
    return {
        getDiffObj        : _getDiffObj,
        setIdDocMod       : _setIdDocMod,
        getIdDocMod       : _getIdDocMod,
        getTableDtFlujo   : _getTableDtFlujo,
        getTableDsFlujo   : _getTableDsFlujo
    }
} ) ()

/**
 * 
 * @param {*elemento a buscar en diff} element 
 * @param {*elemento o elementos diff} toDiff 
 * @return {* booleano }
 *          true: si existe
 *          fsle: si no es parte de diff
 */
function isToDiff(element, toDiff) {
    let diff = [];
    let result = false;
    if (typeof toDiff === 'string')
        diff.push(toDiff);
    else
        diff = toDiff;
    let max = diff.length;
    for (let x = 0; x < max; x++) {
        if (diff[x].localeCompare(element) === 0) {
            result = true;
            break;
        }
    }
    return result;
}
/**
 * 
 * @param {*objeto base a comparar} a 
 * @param {*objeto para ver diferencias} b 
 * @return {* booleano / Objeto / Array}
 *          booleano:   Comparacion entre tipos basicos string, number, boolean, null, undefined
 *          Objeto:     En caso de existir diferencias en Object se tendra un objeto con las mismas
 *          Array:      En caso de existir diferencias en Array se tendra un Arra con las diferencias
 */
function compare(a, b, toDiff) {
    let aType = typeof a;
    let bType = typeof b;
    if (aType == bType) {
        if (aType === 'number' && bType === 'number' && !isNaN(a) && !isNaN(b))
            return a === b;
        if (aType === 'string')
            return (a.localeCompare(b) == 0);
        if (aType == 'boolean')
            return a === b;
        if (aType === 'null')
            return true;
        if (aType === 'undefined') {
            console.warn("tipo de dato indefinido");
            return true;
        }
        if (aType === 'object') {
            if (a == null && b == null)
                return true;
            if (a.constructor.name == 'Object') {
                let aKey = Object.getOwnPropertyNames(a);
                let bKey = Object.getOwnPropertyNames(b);
                aKey.sort();
                bKey.sort();
                let max = aKey.length;
                let elementA, elementB;
                let result;
                let obj = {};
                for (let x = 0; x < max; x++) {
                    elementA = a[aKey[x]];
                    elementB = b[aKey[x]];
                    if (isToDiff(aKey[x], toDiff) === true) {
                        elementB = elementA;
                        result = false;
                    } else {
                        if (typeof elementB !== 'undefined') {
                            result = compare(elementA, elementB, toDiff);
                        } else
                            result = true;
                    }
                    if (typeof result == 'boolean' ? !result : false)
                        obj[aKey[x]] = elementB;
                    else if (typeof result === 'object')
                        obj[aKey[x]] = result;
                }
                max = bKey.length;
                for (let x = 0; x < max; x++) {
                    if (typeof a[bKey[x]] === 'undefined')
                        obj[bKey[x]] = b[bKey[x]];
                }

                if (Object.keys(obj).length > 0) {
                    let status = true;
                    if (toDiff.length > 0) {
                        aKey = Object.getOwnPropertyNames(obj);
                        let max = aKey.length;
                        status = false;
                        for (let x = 0; x < max; x++) {
                            if (!isToDiff(aKey[x], toDiff)) {
                                status = true;
                                break;
                            }
                        }
                    }
                    if (status)
                        return obj;
                }
                return true;
            } else if (a.constructor.name == 'Array') {
                let max = a.length > b.length ? a.length : b.length;
                let elementA, elementB;
                let result;
                let arr = [];
                let numUndefined = 0;
                let numDif = 0;
                for (let x = 0; x < max; x++) {
                    elementA = a[x];
                    elementB = b[x];
                    result = compare(elementA, elementB, toDiff);
                    if (typeof result == 'boolean') {
                        if (!result) {
                            arr[x] = elementB;
                            if (typeof arr[x] === 'undefined')
                                numUndefined++
                            else
                                numDif++;
                        } else {
                            arr[x] = undefined;
                            numUndefined++;
                        }
                    } else if (typeof result === 'object') {
                        numDif++;
                        arr[x] = result;
                    }
                }
                if (numUndefined <= a.length && numDif === 0)
                    return true;
                else if (arr.length > 0) {
                    return arr;
                }
            }
            return false;
        }
        return false;
    } else {
        return false;
    }
}

export default functions;
const state = {
    elements      : [],
    modulos       : [],    
    idModule      : 0,
    component     : "",
    dialog        : false,
    currentModule : "principal",
    mainLoader    : false,
    navLeft : {
        draw  : false,
        clip  : false,
        tempo : false,
        mini  : false,
        perma : false,
        icon  : "left",
        hover : false
    }
}

const getters = {
    getElementsById: (state) => {        
        return state.idModule == 0 ? [] :  state.elements.find( el => el.id == state.idModule ).sideMenu
    },
    getElements : state => {
        return state.elements.map(el => { return { icon : el.icon, id : el.id, name : el.name } } )
    },
    getElementsByIdConceptos: (state) => {
        var sideMenu = state.elements.find( el => el.id == state.idModule ).sideMenu
        return sideMenu.filter(menu => menu.type == 0 )
    }
}

const actions = {
    // *** 20210102~LuisLópez Controla los valores del navigation left
    setNavState( { commit}, val){
        commit("SET_NAV_STATE", val)
    },
    setElements( { commit }, val) {
        commit('SET_ELEMENTS',val)
    },
    setIdModulo( { commit}, id ){
        commit("SET_IDMODULE", id)
    },
    setComponent( { commit }, val ){
        commit("SET_COMPONENT", val)
    },
    setDialog( { commit }, val ){
        commit("SET_DIALOG", val)
    },
    setCurrentModule({commit},val){
        commit("SET_CURRENTMODULE",val)
    },
    setMainLoader({commit},val){
        commit("SET_MAINLOADER",val)
    }
}

const mutations = {
    SET_NAV_STATE(state,{
        nav,
        val
    }){
        state.navLeft[nav] = val
    },
    SET_IDMODULE(state, id){
        state.idModule = id
    },
    SET_ELEMENTS(state,elements){
        state.elements = elements;
    },
    SET_COMPONENT(state,component){
        state.component = component
    },
    SET_DIALOG( state, val ){
        state.dialog = val
    },
    SET_CURRENTMODULE(state,current){
        state.currentModule = current
    },
    SET_MAINLOADER( state, payload){
        state.mainLoader = payload
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

const state = {
    dataSlider : [1,1],
    hoy        : 1
}

const getters = {
    getDataSlider : ( state ) => state.dataSlider,
}

const actions = {
    setDataSlider( { commit }, val ) {
        commit( 'SET_DATA_SLIDER', val )
    },
    setMaxDate ({commit}, val ){
        commit('SET_MAX_DATE', val)
    }
}

const mutations = {
    SET_DATA_SLIDER( state, val ) {
        state.dataSlider = val;
    },
    SET_MAX_DATE( state, val ) {
        state.hoy = val;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
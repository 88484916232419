import JsTable   from './JsTable.js'
import Msg       from './Msg.js'
import functions from './functions.js'

const ModuleLib = {    
    install:(Vue,config) => {
        Vue.prototype.$JsTable = JsTable
        Vue.prototype.$Msg     = Msg        
        Vue.prototype.$fn      = functions
    }
}
export default ModuleLib  
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/src/locale/es.ts'
Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  lang: {
    locales: { es },
    current: 'es',
  }
});

import setApi from '@/services/setApi';

const R = ( ( ) => {
    let parametros = {
        modulo  : 'base',
        abrev   : 'Bas',
        seccion : 'OPS',
        accion  : 'R',
        request : [
            /**
             * @description Inicializacion de datos de formularios para reportes 
             * su uso es dentro del store
             */
            "getFrmInit",
            "getAccionesConcepto"
        ]
    }    
    return setApi(parametros)
})()

export default R